export function FAQ(props) {

    return (
        <div id="faq" className='text-center container mx-auto' style={{ marginTop: props.sec ? '0px' : 150 + 'px' }}>
            <div className="cards text-center mx-auto col-md-12">
                <h3>FAQs</h3>
                <p>Frequenty Asked Questions</p>
                <br />
                <div class="container">
                    <div class="accordion" id="accordionExample">
                        <div class="item">
                            <div class="item-header" id="headingSix">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseSix" aria-expanded="false"
                                        aria-controls="collapseSix">
                                        How many demo class do you provide?
                                        <i class="fa fa-angle-down"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix"
                                data-parent="#accordionExample">
                                <div class="t-p">
                                    <b>1 Demo Class</b>
                                    <br /><br />
                                    One day is all it takes to turn code chaos into clarity.
                                    {/* Above OM Sai Computer (200 Steps from Mango Chowk Towards Old Purulia Road), Jamshedpur, 831001 */}
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-header" id="headingOne">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        What is your fee structure?
                                        <i class="fa fa-angle-down"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                                data-parent="#accordionExample">
                                <div class="t-p">
                                    <b>Introducing Pay-As-You-Go in Jamshedpur!</b>
                                    <br />
                                    <br />
                                    Experience flexible learning with our Pay-As-You-Go fee structure. Only pay for the classes you attend—no charges for missed classes. Join us in Jamshedpur and learn at your own pace without upfront fees!
                                </div>
                            </div>
                        </div>
                        {/* <div class="item">
                            <div class="item-header" id="headingTwo">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Why your courses termed as 'Coming Soon' ?
                                        <i class="fa fa-angle-down"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#accordionExample">
                                <div class="t-p">
                                    <b>We'll soon open registration for all courses. </b>
                                    <br />
                                    <br />
                                    Before that Join our free crash course and experience our expert instruction.  Don't miss this opportunity to start your learning journey with us!
                                </div>
                            </div>
                        </div> */}
                        <div class="item">
                            <div class="item-header" id="headingThree">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        Are you genuine?
                                        <i class="fa fa-angle-down"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                data-parent="#accordionExample">
                                <div class="t-p">
                                    <b>We are a team of experienced developers who have spent years in the industry. </b>
                                    <br /><br />
                                    Our mission is to empower the next generation by sharing our knowledge and helping them build successful careers in technology.
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-header" id="headingFour">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseFour" aria-expanded="false"
                                        aria-controls="collapseFour">
                                        Do you have any refund policy?
                                        <i class="fa fa-angle-down"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                data-parent="#accordionExample">
                                <div class="t-p">
                                    <b>Refund Policy</b>
                                    <br /><br />
                                    Yes, we offer a flexible pay-as-you-go method, so a refund policy is generally unnecessary. Your first class is free, giving you a risk-free opportunity to experience our teaching before committing further.
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="item-header" id="headingFive">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseFive" aria-expanded="false"
                                        aria-controls="collapseFive">
                                        What is your location?
                                        <i class="fa fa-angle-down"></i>
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive"
                                data-parent="#accordionExample">
                                <div class="t-p">
                                    <b>We have several great options in Jamshedpur!</b>
                                    <br /><br />
                                    The specific location for your group will be finalized and sent to you via SMS or WhatsApp based on your group number!
                                    {/* Above OM Sai Computer (200 Steps from Mango Chowk Towards Old Purulia Road), Jamshedpur, 831001 */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}