export function WhyUs(props) {

    return (
        <div id="why-us" className='text-center container mx-auto'>
            <h3>Why Us</h3>
            <p>The only way to do great work is to love <br /> what you do & <b>we love it!</b></p>

            <div className="container text-left mr-auto pt-5">
                <div className="row">
                    <div className="col-md-6">
                        <img src="/images/why-us.jpg" alt="why us image not available" style={{ width: "100%", height: "100%" }} />
                    </div>
                    <div className="col-md-6 pt-3">
                        <ul
                            className="whyUs-ul"
                        >
                            <li>
                                <b>Expert Faculty:</b> Learn from industry professionals with real-world experience.
                            </li>
                            <br />
                            <li>
                                <b>Customized Learning:</b> Our courses are designed to meet your specific needs.
                            </li>
                            <br />
                            <li>
                                <b>Holistic Development:</b> We focus on both technical skills and soft skills.
                            </li>
                            <br />
                            <li>
                                <b>Strong Industry Connections:</b> Benefit from our network of professionals.
                            </li>
                            <br />
                            <li>
                                <b>Our Portfolio:</b> <a href="https://upgrace.in">Check Here</a>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}