export default function UpdateCard(props) {

    let name = props?.src.split('/')
    name = name[2].split('.')[0]

    return (
        <div className="card col-md-4">
            <div className="card_con">
                {props.src.includes('mp4') ?
                    <video className="card-img-top" controls>
                        <source src={`${props.src}#t=03`} type="video/mp4" />Your browser does not support the video tag.
                    </video>
                    :
                    <img className="card-img-top" src={`${props.src}`} alt="Card image cap" />}
                <span style={{
                    // float: "left",
                    fontSize: "0.8rem",
                    fontWeight: 800
                }}>{props?.title}</span>
                <div className={"card-body"}>
                    {/* <a
                        style={{ opacity: props.href == "" ? 0.2 : 1, background: props.href == "" ? "grey" : "" }}
                        href={props.href == "" ? props.src : props.href}
                        target="_blank"
                        className="btn btn-primary"
                    >
                        {props.href == "" ? "Coming Soon" : "Register Now"}
                    </a> */}
                    <a
                        style={{ opacity: 0.2, pointerEvents: 'none' }}
                        href={`/register?for=${name}`}
                        target="_blank"
                        className="btn btn-primary"
                    >
                        Reg. Closed
                    </a>
                </div>
            </div>
        </div>
    )
}