import { useEffect, useState } from 'react';
import { useRef } from 'react';
import Typed from 'typed.js';

export default function Title() {

    // Create reference to store the DOM element containing the animation
    const el = useRef(null);

    useEffect(() => {

        const typed = new Typed(el.current, {
            strings: window.innerWidth <= 768 ? ['<i>Elevate your <br/> expertise <br/> with us.</i>'] : ['<i>Elevate your <br/> expertise with us.</i>'],
            typeSpeed: 70,
            smartBackspace: true,
            showCursor: false,
            // cursorChar: '|',
            autoInsertCss: true,
            // loop: true,
            // loopCount: Infinity,
        });

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
    }, []);

    return (
        <section className="head_section">
            <div className="container">
                <span className="big_text" ref={el} />
                {/* <h1 className="big_text">Elevate your <br /> Expertise <br /> With us</h1> */}
            </div>
        </section>
    )
}