import { useEffect, useRef, useState } from "react"
import { useSearchParams } from 'react-router-dom';
const $ = require('jquery')

export default function Register() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [show, setShow] = useState(false)

    const [err, setErr] = useState()
    const [msg, setMsg] = useState()
    const [progressBar, setProgressbar] = useState(false)

    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/css/style.css';
        document.head.appendChild(link);
        setTimeout(() => {
            setShow(true)
        }, 500)

        return () => {
            document.head.removeChild(link);
        };
    }, []);

    const pushToSheets = async (data) => {

        const Sheet_Url = "https://script.google.com/macros/s/AKfycbzc0pbA4eICY2129qd35xrN6IQa7qgEOe8bKZk-Q58aSbusP9pL1mVZ8BOrUbXo2GJrzA/exec"
        try {
            await fetch(Sheet_Url, {
                method: 'POST',
                body: data,
                muteHttpExceptions: true,
            }).then((val) => {
                console.log(val, "PUSHED")
                setProgressbar(false)
                $('#register-form')[0].reset()
                setMsg("Thanks for registering! We'll send you updates soon via SMS or WhatsApp. Stay tuned!")
            }).catch(e => {
                console.log("Error", e)
                setErr(JSON.stringify(e))
            })

        } catch (error) {
            console.log(error);
        } finally {
            setProgressbar(false)
        }
    }

    const increaseDots = () => {
        setMsg("Processing.")
        setTimeout(() => {
            setMsg("Processing..")
            setTimeout(() => {
                setMsg("Processing...")
            }, 150)
        }, 150)
    }

    function submitHandler(e) {
        setProgressbar(true)
        e.preventDefault();
        let finalData = {};
        const formData = new FormData(e.target);
        formData.forEach((value, property) => (value ? finalData[property] = value : ""));
        console.log(Object.keys(finalData).length)
        if (Object.keys(finalData).length < 4) { setProgressbar(false); return setErr("Please fill all required fields to proceed!") }
        setErr(null)
        pushToSheets(formData)
    }

    useEffect(() => {
        if (searchParams.get("for") == null)
            window.location.replace('/')
    }, [])

    return (
        <div class="main" style={{ display: show ? "block" : "none" }}>
            <section class="signup">
                <div class="container">
                    <div class="signup-content">
                        <div class="signup-form">
                            <h2 class="form-title">Register</h2>
                            <form onSubmit={(e) => submitHandler(e)} class="register-form" id="register-form">
                                <div class="form-group">
                                    <label for="name"><i class="zmdi zmdi-account material-icons-name"></i></label>
                                    <input type="text" name="name" id="name" placeholder="Your Name" />
                                </div>
                                <div class="form-group">
                                    <label for="email"><i class="zmdi zmdi-email"></i></label>
                                    <input type="email" name="email" id="email" placeholder="Your Email (Optional)" />
                                </div>
                                <div class="form-group">
                                    <label for="phone"><i class="zmdi zmdi-phone"></i></label>
                                    <input type="phone" name="phone" id="phone" placeholder="Your Phone Number" />
                                </div>
                                <div class="form-group">
                                    <label for="language"><i class="zmdi zmdi-globe"></i></label>
                                    <input type="language" name="language" id="language" placeholder="Language (Hindi, English, Both...)" />
                                </div>
                                <div class="form-group" style={{ pointerEvents: 'none' }}>
                                    <label for="interested_in"><i class="zmdi zmdi-star"></i></label>
                                    <input type="interested_in" defaultValue={searchParams.get('for')?.toUpperCase() ?? ''} name="interested_in" id="interested_in" placeholder="Interested In (Python, Java, etc...)" />
                                </div>
                                <p style={{ color: '#8000FF', textAlign: "justify" }}><b>{msg}</b></p>
                                <p style={{ color: 'red' }}><b>{err}</b></p>
                                {/* <div class="form-group">

                                    <input type="checkbox" name="agree-term" id="agree-term" class="agree-term" />
                                    <label for="agree-term" class="label-agree-term"><span><span></span></span>I agree all statements in  <a href="#" class="term-service">Terms of service</a></label>
                                </div> */}

                                <div class="form-group form-button">
                                    {
                                        !progressBar ?
                                            <input type="submit" name="signup" id="signup" class="form-submit" value="Register" />
                                            : <div class="spinner-border text-secondary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                    }

                                </div>

                            </form>
                        </div>
                        {/* <div class="signup-image">
                            <figure className="removeInMobile"><img src="/images/register.png" alt="sing up image" /></figure> */}
                        {/* <a href="#" class="signup-image-link">Thanks for choosing us!</a> */}
                        {/* </div> */}
                    </div>
                </div>
            </section>
        </div>
    )
}