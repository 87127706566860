export default function PCNav(props) {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="#">upgrace</a>
            <img onClick={props.show_nav} className="bars" style={{ width: 50 + 'px' }} src="/assets/bars.png" alt="" />
            <img onClick={props.hide_nav} className="bars2" style={{ display: 'none', width: 50 + 'px' }} src="/assets/bars2.png" alt="" />
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a href="#courses" className="courses nav-link">Courses</a>
                    </li>
                    <li className="nav-item">
                        <a href="#why-us" className="why-us nav-link">Why&nbsp;Us</a>
                    </li>
                    <li className="nav-item">
                        <a href="#faq" className="experience nav-link">FAQs</a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="review nav-link">Testimonials</a>
                    </li> */}
                    <li className="nav-item">
                        <a href="#contact" className="contact nav-link">Contact</a>
                    </li>
                    {/* <li className="nav-item bt_spec"> */}
                        {/* <button class="bt nav-link pb-modalreglog-submit" data-toggle="modal" data-target="#myModal2">Register</button> */}
                        {/* <a target="_blank" href="#" data-toggle="modal" data-target="#myModal2" className="pb-modalreglog-submit bt nav-link">Register</a> */}
                    {/* </li> */}
                </ul>
            </div>
        </nav>
    )
}
